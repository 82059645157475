import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/roles`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/role/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    addRole(ctx, roleData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/role`, { role: roleData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    updateRole(ctx, { id, roleData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${APP_URL}/role/${id}`, { role: roleData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${APP_URL}/role/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
