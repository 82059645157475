import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { sortCompare } from '@/@fake-db/utils'


export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  //let user = $('tables.user')
  const tableColumns = [
    { key: 'user', sortable: true },
    // { key: 'email', sortable: false },
    { key: 'role', sortable: false },
    // { key: 'upLine', sortable: false },
    // { key: 'level', sortable: false },
    // { key: 'status', sortable: true },
    { key: 'currency' },
    { key: 'balance' },    
    { key: 'transaction' },    
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('userName')
  const isSortDirDesc = ref(false)
  const roleFilter = ref(null)
  const statusFilter = ref(true)
  const recordDeleted = ref(false)
  const listUsers = ref([])
  const loadingListUsers = ref(false)
  const idUserSelect = ref('')

  
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const whitelabelSelect = computed(() => {
    return store.state.whitelabelCurrencyNabvar.whitelabel._id
  })
  const currencySelect = computed(() => {
    return store.state.whitelabelCurrencyNabvar.currency
  })

  watch([currentPage, perPage, searchQuery, roleFilter,  statusFilter, recordDeleted, idUserSelect,  whitelabelSelect, currencySelect], () => {
    fetchUsers()
  })

  const  fetchUsers = async () => {

    try{
      loadingListUsers.value = true
      const response = await store.dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        status: statusFilter.value,
        id: idUserSelect.value,
        whitelabel: whitelabelSelect.value,
        currency: currencySelect.value  
      })
      const { users, total } = response.data

      users.forEach(user => {
        user.loadingWalletBalance = false
      })
      listUsers.value = users
      totalUsers.value = total

    }catch(e){
      console.log(e)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching users list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }finally{
      loadingListUsers.value = false
    }
  }
  
  const exportFilter = computed(()=>{
    return {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      role: roleFilter.value,
      status: statusFilter.value,
      id: idUserSelect.value,
      whitelabel: whitelabelSelect.value,
      currency: currencySelect.value
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'Root') return 'primary'
    if (role === 'Admin') return 'warning'
    if (role === 'Agent') return 'success'
    if (role === 'Player') return 'danger'
    return 'dark'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'Root') return 'UserCheckIcon'
    if (role === 'Admin') return 'UserIcon'
    if (role === 'Agent') return 'UsersIcon'
    if (role === 'Player') return 'PlayIcon'
    return 'LayersIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'warning'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    recordDeleted,
    idUserSelect,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    listUsers,
    loadingListUsers,

    // Extra Filters
    roleFilter,
    statusFilter,
    exportFilter
  }
}
