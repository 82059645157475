import axios from '@axios'
import axiosDef from 'axios'
const APP_URL = process.env.VUE_APP_URL
const APP_GATEWAY = process.env.VUE_APP_GATEWAY
const APP_WALLET_CLIENTS = process.env.VUE_APP_WALLET_CLIENTS
const APP_WALLET_WALLETS = process.env.VUE_APP_WALLET_WALLETS
const TOKEN = process.env.VUE_APP_CURRENCYS_TOKEN

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchsWallets(ctx){
      return new Promise((resolve, reject) => {
        axiosDef
          .get(APP_WALLET_WALLETS, {headers: {authorization: TOKEN}})
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },

    //JeanRosa Prueba de objeto
    fetchsTransactions2(){
      return [
          {
            "username": "Jean",
            "walletName": "jeanWallet",
            "from":"debit",
            "balance":
            {
                "amount":50,
                "currency":"USD",
                "currentBalance":50
            },
            "reference":"lc0zhgd00j",
            "SoftDelete":null,
            "createdAt":"2022-04-12T15:51:05.786Z",
            "updatedAt":"2022-04-12T15:51:05.786Z"
        },
        {
            "username": "Alfonso",
            "walletName": "jeanWallet",
            "from":"debit",
            "balance":
            {
                "amount":70.25,
                "currency":"USD",
                "currentBalance":120.25
            },
            "reference":"250zhgd00j",
            "SoftDelete":null,
            "createdAt":"2022-04-19T15:51:05.786Z",
            "updatedAt":"2022-04-19T15:51:05.786Z"
        },
        {
            "username": "Jean",
            "walletName": "Mipto",
            "from":"credit",
            "balance":
            {
                "amount":20.50,
                "currency":"USD",
                "currentBalance":140.75
            },
            "reference":"250zhgd22j",
            "SoftDelete":null,
            "createdAt":"2022-04-15T15:51:05.786Z",
            "updatedAt":"2022-04-15T15:51:05.786Z"
        },
        {
            "username": "Alfonzo",
            "walletName": "Mipto",
            "from":"debit",
            "balance":
            {
                "amount":48.22,
                "currency":"USD",
                "currentBalance":60.75
            },
            "reference":"270zhgd22j",
            "SoftDelete":null,
            "createdAt":"2022-04-16T15:51:05.786Z",
            "updatedAt":"2022-04-16T15:51:05.786Z"
        },
        {
            "username": "Mauro",
            "walletName": "Mipto",
            "from":"credit",
            "balance":
            {
                "amount":124.50,
                "currency":"USD",
                "currentBalance":140.75
            },
            "reference":"274zhgd22j",
            "SoftDelete":null,
            "createdAt":"2022-04-20T15:51:05.786Z",
            "updatedAt":"2022-04-20T15:51:05.786Z"
        },
    ]
    },
    //JeanRosa, Metodo para UserHistory
    fetchsTransactions(ctx, queryParams){
      return new Promise ((resolve, reject)=>{
        axios
          .get(`${APP_URL}/userHistory`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchClients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/clients`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportGateway(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/agentReports`, {params})
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    fetchPlayerBalances(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${APP_URL}/transaction/agent-report-players-balance`, {params})
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },

    fetchRechargeRetirement(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/transaction/recharge-retreat`,  { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
    fetchClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/clients/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APP_URL}/clients`, clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateClient(ctx, {client, id}) {
      return new Promise((resolve, reject) => {
        axios.put(`${APP_URL}/clients/${id}`, client)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addClientWallet(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axiosDef
          .post(APP_WALLET_CLIENTS, clientData, {
            headers: {authorization: TOKEN}})
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },



    addClientGateway(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_GATEWAY_CUSTOMERS, clientData)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },


    removeClient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${APP_URL}/clients/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatStatusClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/clients/${clientData._id}/status/${clientData.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    updateAvailableProducts(ctx, clientId) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${APP_URL}/clients/${clientId}`, {})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
