import axios from '@/libs/axios'
import axiosDef from 'axios'
const WALLET_TOKEN = process.env.VUE_APP_CURRENCYS_TOKEN
const APP_WALLET_WALLETS = process.env.VUE_APP_WALLET_WALLETS

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
  
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsersByName(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/findUser' , {
            params: queryParams
          } )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsersByNameForBranch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users/branchByUserName/', queryParams )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUsersExcludeIncludes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.post('/showStatusByBranch/', queryParams )
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    fetchUsersAll() {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/all')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users',userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/users/'+userData._id, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    documentCheck(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users/documentCheck/'+userData.id, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPlayer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/register', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    authUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/signin', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserPassword(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/users/reset-password`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    lockUnlockUser(ctx, obj) {
      return new Promise((resolve, reject) => {
        axios.post(`/users/changeStatus`, obj)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // POST: /users/changeStatus
    // request:
    // id de usuario => required
    // id de whitelabel => required
    // currency => required
    // status => required
    // isAll => optional (aqui es donde dices si uno solo o toda la rama)


    //// wallet
    addUserWallet(ctx, userWallet) {
      console.log('entre a adduserwallet: ', userWallet)
      return new Promise((resolve, reject) => {
        axiosDef
          .post(APP_WALLET_WALLETS, userWallet, {
            headers: {authorization: WALLET_TOKEN}})
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },
    fetchUserWallets() {
      return new Promise((resolve, reject) => {
        axiosDef
          .get(APP_WALLET_WALLETS, {
            headers: {authorization: WALLET_TOKEN}})
          .then(response => resolve(response.data))
          .catch(error => reject(error.response))
      })
    },

    fetchUserWallet(ctx, { walletId, currency }) {
      return new Promise((resolve, reject) => {
        axiosDef
          .get(`${process.env.VUE_APP_WALLET_WALLET}/${walletId}/balance?currency=${currency}`, {
            headers: {authorization: WALLET_TOKEN}})
          .then(response => resolve(response))
          .catch(error => reject(error)) 
      })
    },

    transactionCreditDebit({ commit, getters }, data) {
      return new Promise((resolve, reject) => {
      axiosDef
      .post(`${process.env.VUE_APP_WALLET_WALLET}/${data.walletId}/${data.details.type}?currency=${data.currency}`, data,
      {headers: {authorization: WALLET_TOKEN}})
        .then(res => {resolve(res.data)})
        .catch(error => {reject(error.response.data)})
      })
    },

    walletUser({}, { username }) {
      return new Promise((resolve, reject) => {
        axiosDef
          .get(`${process.env.VUE_APP_WALLET}/v1/username/${username}/wallets`, {
            headers: {authorization: WALLET_TOKEN}})
          .then(response => resolve(response.data))
          .catch(error => reject(error)) 
      })
    },
    setCreditOrDebit({ commit, getters }, transaction) {
        return new Promise((resolve, reject) => {
          axios.post(`wallet-internal/transfer`, transaction)
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response.data)
            })
        })
    },
    setCreditOrDebit1({ commit, getters }, { walletId, typeTransaction, currency, transaction }) {
        return new Promise((resolve, reject) => {
          axiosDef.post(`${process.env.VUE_APP_WALLET}/v1/wallet/${walletId}/${typeTransaction}?currency=${currency}`, transaction,{
            headers: {authorization: WALLET_TOKEN}})
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                reject(error.response.data)
            })
        })
    },
    fetchBalanceWallet({ commit, getters }, { walletId, currency }) {
      return new Promise((resolve, reject) => {
        axiosDef
          .get(`${process.env.VUE_APP_WALLET}/v1/wallet/${walletId}/balance?currency=${currency}`, {
            headers: {authorization: WALLET_TOKEN}})
          .then(response => resolve(response.data))
          .catch(error => reject(error)) 
      })
    },
    forceUserLogout(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
            .post('/event/logOffRemote', payload)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    makeWithdrawalByPin(ctx,payload){
      return new Promise((resolve, reject) => {
        axios
            .post('/withdrawal/make', payload)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    getWithdrawalByPin(ctx,payload){
      return new Promise((resolve, reject) => {
        axios
            .put('/withdrawal', payload)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    createUserExclusionBySystem(ctx,payload){
      return new Promise((resolve, reject) => {
        axios
            .post('/exclusion-system', payload)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    getUserExclusionBySystem(ctx,id){
      return new Promise((resolve, reject) => {
        axios
            .get(`/exclusion-system/userId/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    deleteUserExclusionBySystem(ctx,id){
      return new Promise((resolve, reject) => {
        axios
            .delete(`/exclusion-system/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    }



  },
}
