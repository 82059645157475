<template>
  <div>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

         <!-- Field: Firstname -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="First Name"
            label-for="firstname"
          >
            <b-form-input
              id="firstname"
              v-model="userData.firstName"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Lastname -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Last Name"
            label-for="lastname"
          >
            <b-form-input
              id="lastname"
              v-model="userData.lastName"
            />
          </b-form-group>
        </b-col>

        <!-- Field: email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="eamil"
              v-model="userData.email"
            />
          </b-form-group>
        </b-col>


        <!-- Field: Upline -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Upline"
            label-for="upline"
          >
            <b-form-select
                input-id="upline"
                v-model="userData.upLine"
                :options="usersForParent"
               
              />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="userData.role != 'player'">
            <!-- Field: Roles allowed -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Add new role allowed"
            label-for="roles-allowed"
          >
            <v-select
                input-id="roles-allowed"
                v-model="newRoleAllowed"
                :options="roles"
                input-value="_id"
                label="name"
                
              />
          </b-form-group>
        </b-col>

        <b-col
        cols="12"
          md="8"
        >
          <b-form-group
            label="Roles allowed"
            label-for="roles-allowed"
          >
            <tr
              v-for="item in userData.rolesAllowed"
              :key="item"
              input-id="roles-allowed"
            >
              <td>
                <div class="demo-inline-spacing">
                  {{ roleName(item) }}
                </div>
              </td>
              <button 
              @click.prevent="deleteRoleAllowed(item)"
              >
                Delete role
              </button>
            </tr>
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateUser(userData._id)"
    >
      Save Changes
    </b-button>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, 
  BCardTitle, BFormCheckbox, BFormSelect
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUsersList from '../users-list/useUsersList'
import roleStoreModule from '../../../pages/roles/roleStoreModule'
import clientStoreModule from '../../../pages/clients/clientStoreModule'
import userStoreModule from '../userStoreModule'
import store from '@/store'
import { forEach } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const ROLE_APP_STORE_MODULE_NAME = 'app-role'
    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const users = ref([])
    const usersForParent = ref([])  
    const newUplineChanged = ref([])
    const oldUplineChanged = ref([])   
    const usersLoggedChanged = ref([])  

    store.dispatch('app-user/fetchUsersAll')
      .then(response => { 
        var newArray = response.data.users.filter((item) => item.status == true )
        users.value = newArray
      })
      .then(() => {
        // Options for parents are only users related to logged user tree
        const userLogged = JSON.parse(localStorage.getItem('userData'))
        if (userLogged) {
          // buscar todos los usuario donde el user logged es parent
          users.value.forEach(element => {
            if (element._id == userLogged._id){
              var options = {
                  value: element._id,
                  text: element.userName
                }
                usersForParent.value.push(options)
            }
            if (element.parents) { 
              var family = element.parents.find(user => user == userLogged._id)
              if (family) {
                var options = {
                  value: element._id,
                  text: element.userName
                }
                usersForParent.value.push(options)
              }
            }
          });
        }
    })

    const roles = ref([])
    const rolesAllowed = ref([])   
  
    store.dispatch('app-role/fetchRoles')
      .then(response => { 
        roles.value = response.data.roles})
      .then(() => {
        const userLogged = JSON.parse(localStorage.getItem('userData'))
        if (userLogged) {
          userLogged.rolesAllowed.forEach(role => {
            var options = {
              value: '',
              text: ''
            }
            options.value = role
            var roleName = roles.value.find(item => item._id == role)
            if (roleName) {
              options.text = roleName.name
            }
            rolesAllowed.value.push(options)
          })
        }
      })

    const clients = ref([])
    store.dispatch('app-client/fetchClients')
      .then(response => { 
        clients.value = response.data.items 
        })

    const { resolveUserRoleVariant } = useUsersList()
  
    const statusOptions = [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ]

    return {
      resolveUserRoleVariant,
      avatarText,
      users,
      roles,
      clients,
      statusOptions, 
      rolesAllowed,
      usersForParent,
      newUplineChanged,
      oldUplineChanged,
      usersLoggedChanged
     
    }
  },
  data() {
    return {
      newRoleAllowed: '',
      newClient: '',
      oldUpline: '',
    }
  },
  methods: {
    clientName(id) {
      const found = this.clients.find(element => element._id == id)
      if (found) {
        return found.name
      }
    },  
    
    roleName(id) {
      const found = this.roles.find(element => element._id == id)
      if (found) {
        return found.name
      }
    },  

    updateUser(Id) {
      if (this.newRoleAllowed != '') {
        this.addRoleAllowed()
      }

      this.validateUplineChange()
      // when it returns from this function there are three variables updated
      // this.userData         : data of the user whose upline was changed
      // this.newUplineChanged : data of the new upline, a child was included
      // this.oldUplineChanged : data of the old upline, a child was 
      
      console.log('new upline data: ', this.newUplineChanged)
      console.log('old upline data: ', this.oldUplineChanged)

     try {
        // Update user changed
        store.dispatch('app-user/updateUser', { id: Id, userData: this.userData})
        .then(() => {
          if (this.oldUpline != '') {
            // Update new upline
            store.dispatch('app-user/updateUser', { id: this.newUplineChanged.value._id, userData: this.newUplineChanged.value})
            .then( () => {
               // Update old upline
              store.dispatch('app-user/updateUser', { id: this.oldUplineChanged.value._id, userData: this.oldUplineChanged.value})
              .then( () => {
                // this update is for users who have children 
                this.updateParentsOfUserChangedChildren()
                /// Update localstorage, it is only justified if the logged in user is involved in the change
                const userLogged = JSON.parse(localStorage.getItem('userData'))
                store.dispatch('app-user/fetchUser', { id: userLogged._id })
                .then(response => { 
                  localStorage.setItem('userData', JSON.stringify(response.data.data))
                })
              })
            })
          }
          this.$router.go(0)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'User updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
      }catch (error){
        console.log("error updating user: ", error)
        //throw error
      }
    }, // end updateUser

    addRoleAllowed() {
      var roleAllowed = this.userData.rolesAllowed.find(role => role == this.newRoleAllowed)
     
      if (!roleAllowed) {
        this.userData.rolesAllowed.push(this.newRoleAllowed)
        this.newRoleAllowed = ''
      }else {
       this.newRoleAllowed = ''
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Role already exists',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          }
        })
      }
    },

    deleteRoleAllowed(roleId) {
      var i = this.userData.rolesAllowed.indexOf( roleId )
      if ( i !== -1 ) {
         this.userData.rolesAllowed.splice( i, 1 );
      } 
    },

    validateUplineChange() {
      var userDataBefore = this.users.find(user => user._id == this.userData._id)
      if (userDataBefore){
        // it checks if  changed the upline (father)
        if (userDataBefore.upLine != this.userData.upLine) {
          this.oldUpline = userDataBefore.upLine
          // looking for a new upLine
          var newUpline = this.users.find(user => user._id == this.userData.upLine)
          if (newUpline){
            // the data of the user to be changed is updated
            this.userData.level = newUpline.level + 1
            var newParents = newUpline.parents
            newParents.push(this.userData.upLine)
            this.userData.parents = newParents
          }
          this.includeChildInNewUpline()  
          this.removeChildOfOldUpline() 
        }
      }
    },

    includeChildInNewUpline () {
      var newUpline = this.users.find(user => user._id == this.userData.upLine)
      if (newUpline) {    
        if (newUpline.children[0].userId == "x") {
            newUpline.children [0].userId = this.userData._id
        }else {
          const children = {
            node: 'x',
            userId: this.userData._id
          }
          newUpline.children.push(children)
        }
        this.newUplineChanged.value = newUpline
      }
    },

    removeChildOfOldUpline () {
      var oldUpline = this.users.find(user => user._id == this.oldUpline)
      if (oldUpline){
        var newArray = oldUpline.children.filter((item) => item.userId !== this.userData._id )
        oldUpline.children = newArray

        this.oldUplineChanged.value = oldUpline
      }
    },

     // This function changes the parents of all descending children[], players has not children
    updateParentsOfUserChangedChildren (newUpline) {
      if (this.userData.children) {
        this.userData.children.forEach(child => {
          var userChildX = this.users.find(user => user._id == child.userId)
          if (userChildX){
            userChildX.parents = newUpline.parents
            userChildX.parents.push(this.userData._id)
            store.dispatch('app-user/updateUser', { id: child.userId, userData: userChildX})

            userChildX.children.forEach(child => {
              var userChildY = this.users.find(user => user._id == child.userId)
              if (userChildY){
                userChildY.parents = userChildX.parents
                userChildY.parents.push(userChildX._id)
                store.dispatch('app-user/updateUser', { id: child.userId, userData: userChildY})

                userChildY.children.forEach(child => {
                  var userChildZ = this.users.find(user => user._id == child.userId)
                  if (userChildZ){
                    userChildZ.parents = userChildY.parents
                    userChildZ.parents.push(userChildY._id)
                    store.dispatch('app-user/updateUser', { id: child.userId, userData: userChildZ})
                    
                    userChildZ.children.forEach(child => {
                      var userChildW = this.users.find(user => user._id == child.userId)
                      if (userChildW){
                        userChildW.parents = userChildZ.parents
                        userChildW.parents.push(userChildZ._id)
                        store.dispatch('app-user/updateUser', { id: child.userId, userData: userChildW})
                      }
                    })
                  } 
                })
              }
            })
          }  
        })
      }
    }, // end updateParentsOfUserChangedChildren

  }, // methods end
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
